import React from 'react';
import './Footer.css';
import Footerlogo2 from './Footerlogo2.png';
import FacebookIcon from '@material-ui/icons/Facebook';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import { useTranslation } from 'react-i18next';
import MessengerCustomerChat from 'react-messenger-customer-chat';

const Footer = () => {
  const { t } = useTranslation('footer');
  return (
    <footer className="page-footer font-small ">
      <div className="footer-content pt-4 pb-3">
        <div className="grid grid-cols-1 mx-5 md:grid-cols-3 ">
          <div className="mb-50 col-span-1">
            <div className="footer-widget">
              <div className="footer-logo flex justify-end">
                <a href="/">
                  <img src={Footerlogo2} className="img-fluid" alt="logo" />
                </a>
              </div>
              <div className="footer-text pos-footer-text">
                <p>{t('footer-description')}</p>
                <p>{t('footer-description2')}</p>
                <p>{t('footer-address')}</p>
              </div>
            </div>
          </div>
          <div className="mb-30 col-span-1 flex justify-center">
            <div className="footer-widget">
              <div className="footer-widget-heading">
                <h3>{t('links')}</h3>
              </div>

              <div className="footer-links">
                <a href="/">{t('home')}</a>
              </div>
              <div className="footer-links">
                <a href="/contact-us">{t('contact-us')}</a>
              </div>
              <div className="footer-links">
                <a href="/about-us">{t('about-us')}</a>
              </div>
            </div>
          </div>
          <div className="mb-50 col-span-1  flex justify-center">
            <div className="footer-widget">
              <div className="footer-widget-heading">
                <h3>{t('contact-us-big')}</h3>
              </div>
              <div className="footer-text mb-25">
                <div className="footer-social-icon">
                  <div className="footer-links">
                    <a href="https://www.facebook.com/3delctronics">
                      <i>
                        <FacebookIcon /> 3D electronics
                      </i>
                    </a>
                  </div>

                  <div className="footer-links">
                    <a href="mailto:3d@3d-iraq.com">
                      <i>
                        <EmailIcon /> 3d@3d-iraq.com
                      </i>
                    </a>
                  </div>
                  <div className="footer-links">
                    <a href="tel:009647700612084">
                      <i>
                        <PhoneIcon /> 964770061208 +
                      </i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MessengerCustomerChat pageId="213822528690927" appId="120788099862393" />
    </footer>
  );
};
export default Footer;
